import React, { useContext } from "react";

import { GlobalContext } from "../contexts/GlobalContext";

export default function Header() {
  const { session } = useContext(GlobalContext);

  return (
    <div>
      {session.accessToken && (
      <div className="columns is-multiline is-centered is-vcentered">
        <div className="column is-narrow has-text-centered-mobile">
          <div className="content">
            <img src={session.customerLogo} alt="logo" />
          </div>
        </div>
        <div className="column is-narrow has-text-centered-mobile">
          <div className="content">
            <h3>{session.customerName}</h3>
            <h6 className="mb-0">{session.qsScopeType}</h6>
            <label className="mb-0">{session.qsScopeKeys}</label>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};
