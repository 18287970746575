export const AppRoutes = {
  HomePage: "/",
  Login: "/login/:userToken/:customerCode",
  CreateActionPlan: "/create-action-plan/:userToken/:customerCode/:projectID/:originType",
  ActionPlanList: "/action-plans",
  ActionPlan: "/action-plans/:actionPlanID",
  Task: "/action-plans/:actionPlanID/tasks/:taskID",
  Forbidden: "/forbidden",
  Maintenance: "/maintenance",
  Logout: "/logout",
  DeprecatedLogin: "/login/:userToken/:customerCode/:shopID",
  DeprecatedNewActionPlan: "/new-action-plan/:userToken/:customerCode/:shopID/:projectID/:dimensionNumber/:attributeNumber/:initialScore",
}

export const ResolvedAppRoutes = {
  ActionPlan: ({ actionPlanID }) => `/action-plans/${actionPlanID}`,
  Task: ({ actionPlanID, taskID }) => `/action-plans/${actionPlanID}/tasks/${taskID}`,
  Login: ({ userToken, customerCode }) => `/login/${userToken}/${customerCode}`,
  /* Not used
  CreateActionPlan: ({
    userToken,
    customerCode,
    projectID,
    originType,
  }) => `/create-action-plan/${userToken}/${customerCode}/${projectID}/${originType}`,
  DeprecatedLogin: ({ userToken, customerCode, shopID }) => `/login/${userToken}/${customerCode}/${shopID}`,
  DeprecatedNewActionPlan: ({
    userToken,
    customerCode,
    shopID,
    projectID,
    dimensionNumber,
    attributeNumber,
    initialScore,
  }) => `/new-action-plan/${userToken}/${customerCode}/${shopID}/${projectID}/${dimensionNumber}/${attributeNumber}/${initialScore}`,
  */
}