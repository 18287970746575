import React from 'react';
import { bool, func, string } from 'prop-types';
import { NodeCollapseOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export default function ExpandCollapseButton({
  availableExpand,
  onExpand,
  onCollapse,
  expandText,
  collapseText,
}) {
  if (availableExpand) {
    return (
      <Button
        className="py-0 px-2"
        icon={<NodeExpandOutlined />}
        size="small"
        type="link"
        shape="rounded"
        onClick={onExpand}
      >
        {expandText}
      </Button>
    );
  }
  return (
    <Button
      className="py-0 px-2"
      icon={<NodeCollapseOutlined />}
      size="small"
      type="link"
      shape="rounded"
      onClick={onCollapse}
    >
      {collapseText}
    </Button>
  );
}

ExpandCollapseButton.propTypes = {
  availableExpand: bool.isRequired,
  onExpand: func.isRequired,
  onCollapse: func.isRequired,
  expandText: string.isRequired,
  collapseText: string.isRequired,
};
