import React, { useCallback, useState } from "react";
import { node } from "prop-types";
import { Button, Modal } from "antd";

export default function InfoModal({ textButton, title, body }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = useCallback(() => { setIsModalOpen((prev) => !prev); }, []);

  return (
    <>
      <Button type="link" size="small" onClick={toggleModal}>
        {textButton}
      </Button>
      <Modal title={title} open={isModalOpen} onCancel={toggleModal} footer={null}>
        {body}
      </Modal>
    </>
  );
};


InfoModal.propTypes = {
  textButton: node,
  title: node,
  body: node.isRequired,
};

InfoModal.defaultProps = {
  textButton: 'Ver detalles',
  title: null,
};
