import React from "react";
import Navbar from "./shared/Navbar.jsx";
import Header from "./shared/Header.jsx";
import Footer from "./shared/Footer.jsx";

export default function MainScreen (props) {

  return (
    <section className="hero is-fullheight">
      <div className="hero-head">
        <Navbar/>
      </div>
      <div className="hero-body">
        <div className="container">
          <Header/>
          {props.children}
        </div>
      </div>
      <div className="hero-foot">
      <Footer/>
      </div>
    </section>
  );
};