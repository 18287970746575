import { AppRoutes } from "../utils/app-routes";
import { getTTgoURL, setParams } from "../utils/request-utils";

import { message } from "antd";

const abortSignal = { errorCode: 0, message: "Abort signal" };
const failFetch = { errorCode: -1, message: "Server is down" };
const badJSON = { errorCode: -2, message: "Bad response" };

/**
 * @param userToken Body data - string
 * @param customerCode Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function login(userToken, customerCode, qsArray) {
  const url = setParams(getTTgoURL("login"), null, qsArray);

  const options = {
    method: "POST",
    body: JSON.stringify({
      userToken: userToken,
      customerCode: customerCode,
    }),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param projectID Body data - number
 * @param shopID Body data - number 
 * @param dimensionNumber Body data - number
 * @param attributeNumber Body data - number
 * @param initialScore Body data - number
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function newDeprecatedActionPlan(accessToken, projectID, shopID, dimensionNumber, attributeNumber, initialScore) {
  const url = getTTgoURL("deprecatedActionPlans");

  const options = {
    method: "POST",
    body: JSON.stringify({
      projectID: projectID,
      shopID: shopID,
      dimensionNumber: dimensionNumber,
      attributeNumber: attributeNumber,
      initialScore: initialScore,
      description: "Nuevo plan de acción",
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param customerCode Body data - string,
 * @param projectID Body data - number
 * @param scopeType Body data - string [ScopeType]
 * @param scopeFields Body data - string|number
 * @param originType Body data - string [OriginType]
 * @param originFields Body data - object
 * @param title Body data - string
 * @param description Body data - string
 * @param startDate Body data - string format YYYY/MM/DD
 * @param deadlineDate Body data - string format YYYY/MM/DD
 * @param priority Body data - string ["0" | "1" | "2"]
 * @param status Body data - string ["true" | "false"]
 * @param tags Body data - array of number
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function createActionPlan(
  accessToken,
  customerCode,
  projectID,
  scopeType,
  scopeFields,
  originType,
  originFields,
  initialScore,
  title,
  description,
  startDate,
  deadlineDate,
  priority,
  status,
  reason,
  resource,
  tags,
) {
  const url = getTTgoURL("actionPlans");
  const options = {
    method: "POST",
    body: JSON.stringify({
      customerCode,
      projectID,
      scopeType,
      scopeFields,
      originType,
      originFields,
      initialScore,
      title,
      description,
      startDate,
      deadlineDate,
      priority,
      status,
      reason,
      resource,
      tags,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @param qsArray SearchParams data - Array of object format { id: <qsKey>, value: <qsValue> }
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getActionPlanList(ac, accessToken, qsArray) {
  const url = setParams(getTTgoURL("actionPlans"), null, qsArray);

  const options = {
    signal: ac.signal,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @param qsArray SearchParams data - Array of object format { id: <qsKey>, value: <qsValue> }
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getSummaryActionPlans(ac, accessToken, qsArray) {
  const url = setParams(getTTgoURL("summaryActionPlans"), null, qsArray);

  const options = {
    signal: ac.signal,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param qsArray SearchParams data - Array of object format { id: <qsKey>, value: <qsValue> }
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
 function getExportActionPlansURL(accessToken, qsArray) {
  return setParams(
    getTTgoURL("exportActionPlans"),
    [
      { id: "accessToken", value: accessToken },
    ],
    qsArray,
  );
}

/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getActionPlan(ac, accessToken, actionPlanID) {
  const url = setParams(getTTgoURL("actionPlan"), [
    { id: "actionPlanID", value: actionPlanID },
  ]);

  const options = {
    signal: ac.signal,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param description Body data - string
 * @param startDate Body data - string format YYYY/MM/DD
 * @param deadlineDate Body data - string format YYYY/MM/DD
 * @param priority Body data - string ["0" | "1" | "2"]
 * @param status Body data - string ["true" | "false"]
 * @param tags Body data - array of number
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function updateActionPlan(
  accessToken,
  actionPlanID,
  description,
  startDate,
  deadlineDate,
  priority,
  status,
  reason,
  resource,
  tags,
) {
  const url = setParams(getTTgoURL("actionPlan"), [
    { id: "actionPlanID", value: actionPlanID },
  ]);

  const options = {
    method: "PUT",
    body: JSON.stringify({
      description,
      startDate,
      deadlineDate,
      priority,
      status,
      reason,
      resource,
      tags,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function deleteActionPlan(accessToken, actionPlanID) {
  const url = setParams(getTTgoURL("actionPlan"), [
    { id: "actionPlanID", value: actionPlanID },
  ]);

  const options = {
    method: "DELETE",
    body: JSON.stringify({ }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param message Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function newComment(accessToken, actionPlanID, message) {
  const url = setParams(getTTgoURL("comments"), [
    { id: "actionPlanID", value: actionPlanID },
  ]);

  const options = {
    method: "POST",
    body: JSON.stringify({
      message: message
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param title Body data - string
 * @param details Body data - string
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function newTask(accessToken, actionPlanID, title, textDetails, assignedTo, status, observations) {
  const url = setParams(getTTgoURL("tasks"), [
    { id: "actionPlanID", value: actionPlanID },
  ]);

  const options = {
    method: "POST",
    body: JSON.stringify({
      title: title,
      textDetails: textDetails,
      assignedTo: assignedTo,
      status: status,
      observations: observations
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
* @param ac AbortController object
* @param accessToken Header data - Authenticated session token
* @param actionPlanID URL data - URI param
* @param taskID URL data - URI param
* @returns Response JSON data in resolve; Response JSON error in reject.
*/
function getTask(ac, accessToken, actionPlanID, taskID) {
 const url = setParams(getTTgoURL("task"), [
   { id: "actionPlanID", value: actionPlanID },
   { id: "taskID", value: taskID },
 ]);

 const options = {
   signal: ac.signal,
   method: "GET",
   headers: {
     Authorization: `Bearer ${accessToken}`
   }
 };
 return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param taskID URL data - URI param
 * @param title Body data - string
 * @param assignedTo Body data - number
 * @param status Body data - string ["true" | "false"]
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function updateTask(accessToken, actionPlanID, taskID, title, textDetails, assignedTo, status, observations) {
  const url = setParams(getTTgoURL("task"), [
    { id: "actionPlanID", value: actionPlanID },
    { id: "taskID", value: taskID },
  ]);

  const options = {
    method: "PUT",
    body: JSON.stringify({
      title: title,
      textDetails: textDetails,
      assignedTo: assignedTo,
      status: status,
      observations: observations
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param taskID URL data - URI param
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function deleteTask(accessToken, actionPlanID, taskID) {
  const url = setParams(getTTgoURL("task"), [
    { id: "actionPlanID", value: actionPlanID },
    { id: "taskID", value: taskID },
  ]);

  const options = {
    method: "DELETE",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

/**
 * @param accessToken Header data - Authenticated session token
 * @param actionPlanID URL data - URI param
 * @param taskID URL data - URI param
 * @param fileList Body data
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function uploadFiles(accessToken, actionPlanID, taskID, fileList) {
  const url = setParams(getTTgoURL("uploadRequest"), [
    { id: "actionPlanID", value: actionPlanID },
    { id: "taskID", value: taskID },
    { id: "quantity", value: Array.isArray(fileList) ? fileList.length : 0},
  ]);

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        if (response.ok) {
          // Expected fetch response status (ok: 200)
          response
            .json()
            .then(({ signedList }) => {
              // Expected response body data: JSON
              if (signedList && Array.isArray(signedList)) {
                Promise.all(signedList.map((data, index) =>
                  fetch(data.signedURL, {
                        method: "PUT",
                        body: fileList[index],
                        headers: {
                          "Content-Type": fileList[index].type
                        }
                  })
                  .then(response => {
                    if (response.ok) {
                      return fetch(
                        setParams(getTTgoURL("attachments"), [
                          { id: "actionPlanID", value: actionPlanID },
                          { id: "taskID", value: taskID },
                        ]),
                        {
                          method: "POST",
                          body: JSON.stringify({
                            filename: fileList[index].name,
                            size: fileList[index].size,
                            path: data.path
                          }),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`
                          }
                        }
                      );
                    }
                  })
                ))
                .then(responses => {
                  fetch(
                    setParams(getTTgoURL("attachments"), [
                      { id: "actionPlanID", value: actionPlanID },
                      { id: "taskID", value: taskID },
                    ]),
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${accessToken}`
                      }
                    }
                  )
                  .then(response => {
                    if (response.ok) {
                      response
                        .json()
                        .then(json => {
                          resolve(json);
                        })
                        .catch(error => {
                          reject(badJSON);
                        });
                    } else {
                      response
                        .json()
                        .then(json => {
                          reject(json);
                        })
                        .catch(error => {
                          reject(badJSON);
                        });
                    }
                  })
                  .catch(error => {
                    reject(failFetch);
                  });
                })
              } else {
                reject(badJSON);
              }
              
            })
            .catch(error => {
              reject(badJSON);
            });
        } else {
          response
            .json()
            .then(json => {
              reject(json);
            })
            .catch(error => {
              reject(badJSON);
            });
        }
      })
      .catch(error => {
        reject(failFetch);
      });
  });
}


/**
 * @param ac AbortController object
 * @param accessToken Header data - Authenticated session token
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getCustomerTags(accessToken) {
  const url = getTTgoURL("customerTags");

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return sendRequest(url, options);
}

const defaultCatchValidation = (json, navigate) => {
  // Check JSON error type
  if (!isNaN(json.errorCode) && json.message) {
    // Expected JSON data: Positive error-codes are generated in backend, any else come from task-tracking
    switch (json.errorCode) {
      case 3: // Invalid action plan scope
        console.error("Invalid action plan scope: ", json);
        break;
      case 2: // Permission denied
        console.error("Permission denied: ", json);
        navigate(AppRoutes.Forbidden);
        break;
      case 1: // Session token not-found/expired
        console.error("Expired session token: ", json);
        navigate(AppRoutes.Logout);
        break;
      case 0: // Abort signal triggered nothing to do
        break;
      case -1: // Fetch has been failed, maybe server is down
        console.error("Fetch failed");
        navigate(AppRoutes.Maintenance);
        break;
      case -2: // Cant parse response as JSON, for some reason backend failed.
        console.error("Bad JSON response");
        message.error("El servidor no pudo procesar la petición.");
        break;
      default:
        // Any other errorCode ignored
        console.error("Ignored error code:", json);
        break;
    }
  } else {
    // Unexpected json data from backend: Validation error / Internal error.
    console.error("Unexpected error: ", json);
    message.error("El servidor no pudo validar la petición.");
  }
}

const sendRequest = (url, options) =>
  new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        if (response.ok) {
          // Expected fetch response status (ok: 200)
          response
            .json()
            .then(json => {
              // Expected response body data: JSON
              resolve(json);
            })
            .catch(error => {
              // Unexpected body response data: non-JSON
              reject(badJSON);
            });
        } else {
          response
            .json()
            .then(json => {
              // Expected response body error data: JSON
              reject(json);
            })
            .catch(error => {
              // Unexpected response body error data: non-JSON
              reject(badJSON);
            });
        }
      })
      .catch(error => {
        if (error.name === "AbortError") {
          // Abort fetch signal triggered
          console.log("Abort signal !");
          reject(abortSignal);
        }
        // Unexpected fetch error
        console.log("Unexpected fetch ERROR catch: ", error);
        reject(failFetch);
      });
  });

export {
  defaultCatchValidation,
  login, newDeprecatedActionPlan, createActionPlan,
  getActionPlanList, getSummaryActionPlans, getExportActionPlansURL,
  getActionPlan, updateActionPlan, deleteActionPlan, newComment, newTask,
  getTask, updateTask, deleteTask, uploadFiles,
  getCustomerTags,
};
