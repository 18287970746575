import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadSpinner from "./shared/LoadSpinner";
import { AppRoutes } from "../utils/app-routes";

export default function DefaultScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AppRoutes.HomePage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadSpinner />
  );
};
