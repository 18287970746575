import React, { useEffect, useContext } from "react";
import { GlobalContext } from "./contexts/GlobalContext.jsx";

export default function LogoutScreen() {
  const { cleanSession } = useContext(GlobalContext);

  useEffect(() => {
    cleanSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="loading-block weglot-translate">
      <label>{"La sesión ha finalizado."}</label>
    </div>
  );
};
