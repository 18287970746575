import React from "react";
import { number, shape } from "prop-types";
import { Tag, Typography } from "antd";
import ProgressCard from "./ProgressCard";

const { Title } = Typography;

export default function StatusSummary({ summary }) {
  if (summary === undefined) { return null; }

  const { total, active, expired, closed } = summary;
  return (
    <div
    style={{
      display: 'flex',
      gap: 10,
      flexWrap: 'wrap'
    }}>
      <ProgressCard
        title={<Title level={5} className="m-0">Total</Title>}
        current={total}
        minWidth={50}
      />
      {active && (
        <ProgressCard
          title={<Tag color="blue" style={{ paddingBottom: 1 }}>No expirados</Tag>}
          current={active.current}
          percentage={active.percentage}
          minWidth={100}
        />
      )}
      {expired && (
        <ProgressCard
          title={<Tag color="error" style={{ paddingBottom: 1 }}>Expirados</Tag>}
          current={expired.current}
          percentage={expired.percentage}
          minWidth={100}
        />
      )}
      {closed && (
        <ProgressCard
          title={<Tag style={{ paddingBottom: 1 }}>Cerrados</Tag>}
          current={closed.current}
          percentage={closed.percentage}
          minWidth={100}
        />
      )}
    </div>
  );
};

StatusSummary.propTypes = {
  summary: shape({
    total: number,
    active: shape({
      current: number,
      total: number,
      percentage: number,
    }),
    expired: shape({
      current: number,
      total: number,
      percentage: number,
    }),
    closed: shape({
      current: number,
      total: number,
      percentage: number,
    }),
  }),
};

StatusSummary.defaultProps = {
  summary: undefined,
};
