import React, { useState, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../utils/app-routes";

export default function Navbar() {
  const { session } = useContext(GlobalContext);
  const [active, setActive] = useState("");
  const toggleMenu = () => {
    setActive(!active);
  };
  return (
    <div className="navbar-container">
      <nav className="navbar has-shadow">
        <div className="container">
          <div className="navbar-brand">
            <label className="navbar-item label-title">Sistema de seguimiento</label>
            {session.accessToken && (
            <div
              className={"navbar-burger burger " + (active ? "is-active" : "")}
              data-target="navMenu"
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            )}
          </div>
          {session.accessToken && (
          <div className={"navbar-menu weglot-translate " + (active ? "is-active" : "")}>
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a className="navbar-link"><label className="label-name">{`${session.userName}`}</label></a>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to={AppRoutes.HomePage}>{"Pantalla de inicio"}</Link>
                  <Link className="navbar-item" to={AppRoutes.ActionPlanList}>{"Planes de acción"}</Link>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </nav>
    </div>
  );
};
