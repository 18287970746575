import React, { createContext, useCallback, useMemo, useState } from 'react';
import { node } from 'prop-types';
import {
  getSessionCookie,
  setSessionCookie,
  updateSessionCookie,
  cleanSessionCookie
} from "../../utils/cookies-utils";

export const GlobalContext = createContext();

export default function GlobalContextProvider({ children }) {

  const [session, setSessionState] = useState(getSessionCookie());
  const setSession = useCallback((session) => {
    setSessionCookie(session);
    setSessionState(getSessionCookie());
  }, []);

  const cleanSession = useCallback(() => {
    cleanSessionCookie();
    setSessionState(getSessionCookie());
  }, []);

  const updateSession = useCallback((partialSession) => {
    updateSessionCookie(partialSession);
    setSessionState(getSessionCookie());
  }, []);

  const getIndicatorColor = useCallback((indicator, value) => {
    if (typeof value !== 'number') { return '' }
    try {
        return session.more.indicatorScales[indicator]['limits']
        .find(element => value <= element.value)['color'];
    }
    catch(error) {
        return '';
    }
  }, [session]);

  const getActionPlanPriorityData = useCallback((priority) => {
    try {
      return session.more.actionPlan.priority[`${priority}`]
    }
    catch(error) {
      return { text: '', color: '' };
    }
  }, [session]);

  const getActionPlanStatusData = useCallback((status) => {
    if (status === undefined || status === null) {
      return {text: '', color: ''};
    }

    try {
      return session.more.actionPlan.status[`${status}`]
    }
    catch(error) {
      return { text: '', color: '' };
    }
  }, [session]);

  const getTaskStatusData = useCallback((status) => {
    if (status === undefined || status === null) {
      return {text: '', color: ''};
    }
    try {
      return session.more.task.status[`${status}`]
    }
    catch(error) {
      return { text: '', color: '' };
    }
  }, [session]);

  const ctxValue = useMemo(() => ({
    session,
    setSession,
    cleanSession,
    updateSession,
    getIndicatorColor,
    getActionPlanPriorityData,
    getActionPlanStatusData,
    getTaskStatusData,
  }), [
    session,
    setSession,
    cleanSession,
    updateSession,
    getIndicatorColor,
    getActionPlanPriorityData,
    getActionPlanStatusData,
    getTaskStatusData,
  ]);

  return (
    <GlobalContext.Provider value={ctxValue}>
      {children}
    </GlobalContext.Provider>
  );
}

GlobalContextProvider.propTypes = {
  children: node.isRequired,
};
