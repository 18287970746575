import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import ProgressBar from "./shared/ProgressBar.jsx";
import { defaultCatchValidation, getActionPlan, updateActionPlan, deleteActionPlan, newComment, newTask, getCustomerTags} from "../services/task-tracking";
import dayjs from "dayjs";
import { Breadcrumb, Button, DatePicker, Flex, Input, Modal, Table, Tooltip, Select, Spin, Tag, Typography } from "antd";
import { Comment } from "@ant-design/compatible";
import { DeleteOutlined, EditFilled, HomeFilled, PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { AppRoutes, ResolvedAppRoutes } from "../utils/app-routes.js";
import { classNamesInput, classNamesInputTextarea, classNamesModal } from "../utils/constants.js";

const { RangePicker } = DatePicker;

let ac = new AbortController();

export default function ActionPlanScreen() {
  const navigate = useNavigate();
  const {
    session,
    getActionPlanStatusData,
    getActionPlanPriorityData,
    getTaskStatusData,
  } = useContext(GlobalContext);

  const { actionPlanID } = useParams();

  const { control, handleSubmit, reset } = useForm({ defaultValues: { message: '' } });

  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [showTagFeature, setShowTagFeature] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);

  const [loading, setLoading] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [actionPlan, setActionPlan] = useState({});
  const [comments, setComments] = useState([]);

  // Data used to edit AssignTo field
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [hasAssignableUsers, setHasAssignableUsers] = useState(false);
  const [defaultAssignableUser, setDefaultAssignableUser] = useState(0);

  // Modal: Delete action-plan
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [deletingActionPlan, setDeletingActionPlan] = useState(false);
  const showDeleteModal = () => {
    setIsVisibleDeleteModal(true);
  };
  const handleDeleteYes = () => {
    if (deletingActionPlan) {return;}

    setDeletingActionPlan(true);
    deleteActionPlan(session.accessToken, actionPlanID)
    .then(json => {
      navigate(AppRoutes.ActionPlanList);
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    })
    .finally(() => {
      setIsVisibleDeleteModal(false);
      setDeletingActionPlan(false);
    });
  };
  const handleDeleteNo = () => {
    if (deletingActionPlan) {return;}
    setIsVisibleDeleteModal(false);
  };

  // Modal: Edit action-plan
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [editDescription, setEditDescription] = useState("");
  const [editDescriptionRequired, setEditDescriptionRequired] = useState(false);
  const [editStartDate, setEditStartDate] = useState(dayjs());
  const [editDeadlineDate, setEditDeadlineDate] = useState(dayjs());
  const [editPriority, setEditPriority] = useState("0");
  const [editStatus, setEditStatus] = useState("false");
  const [editTags, setEditTags] = useState([]);
  const [editReason, setEditReason] = useState("");
  const [editResource, setEditResource] = useState("");
  const [updatingActionPlan, setUpdatingActionPlan] = useState(false);
  const showEditModal = () => {
    setEditDescription(actionPlan.description);
    setEditStartDate(dayjs(actionPlan.startDate));
    setEditDeadlineDate(dayjs(actionPlan.deadlineDate));
    setEditPriority(''+actionPlan.priority);
    setEditStatus(''+actionPlan.status);
    setEditReason(actionPlan.reason);
    setEditResource(actionPlan.resource);
    setEditTags(Array.isArray(actionPlan.tags) ? actionPlan.tags.map(({ id }) => id) : []);
    setIsVisibleEditModal(true);
  };
  const handleEditSave = () => {
    if (updatingActionPlan) {return;}

    // Validations
    let isValid = true;
    const patchedDescription = String(editDescription).trim();
    if (!patchedDescription) {
      isValid = false;
      setEditDescriptionRequired(true);
    }

    const patchedStartDate = editStartDate.format("YYYY/MM/DD");
    if (!patchedStartDate) {
      isValid = false;
    }

    const patchedDeadlineDate = editDeadlineDate.format("YYYY/MM/DD");
    if (!patchedDeadlineDate) {
      isValid = false;
    }

    if(!isValid) {return;}
    
    setUpdatingActionPlan(true);
    updateActionPlan(
      session.accessToken,
      actionPlanID,
      patchedDescription,
      patchedStartDate,
      patchedDeadlineDate,
      editPriority,
      editStatus,
      editReason,
      editResource,
      editTags,
    )
    .then(json => {
      setActionPlan(json.actionPlan);
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    })
    .finally(() => {
      setUpdatingActionPlan(false);
      setIsVisibleEditModal(false);
    });
  };
  const handleEditCancel = () => {
    if (updatingActionPlan) {return;}
    setIsVisibleEditModal(false);
  };

  // Modal: New task
  const [isVisibleNewTaskModal, setIsVisibleNewTaskModal] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [newTaskTitleRequired, setNewTaskTitleRequired] = useState(false);
  const [newTaskAssignedTo, setNewTaskAssignedTo] = useState(0);
  const [newTaskAssignedToRequired, setNewTaskAssignedToRequired] = useState(false);
  const [newTaskStatus, setNewTaskStatus] = useState("false");
  const [newTaskObservations, setNewTaskObservations] = useState("");
  const [newTaskTextDetails, setNewTaskTextDetails] = useState("");
  
  const [creatingTask, setCreatingTask] = useState(false);
  const showNewTaskModal = () => {
    setNewTaskTitle("");
    setNewTaskAssignedTo(defaultAssignableUser);
    setNewTaskStatus(""+false);
    setNewTaskObservations("");

    setNewTaskTextDetails("");
    setIsVisibleNewTaskModal(true);
  };
  const handleNewTaskSave = () => {
    if (creatingTask) {return;}

    // Validations
    let isValid = true;
    const patchedTitle = String(newTaskTitle).trim();
    if (!patchedTitle) {
      isValid = false;
      setNewTaskTitleRequired(true);
    }

    if (!newTaskAssignedTo) {
      isValid = false;
      setNewTaskAssignedToRequired(true);
    }

    if(!isValid) {return;}

    const patchedObservations = newTaskObservations.trim();
    setCreatingTask(true);
    newTask(session.accessToken, actionPlanID, patchedTitle, newTaskTextDetails, Number(newTaskAssignedTo), newTaskStatus, patchedObservations)
      .then(json => {
        navigate(ResolvedAppRoutes.Task({ actionPlanID: actionPlanID, taskID: json.taskID }));
      })
      .catch(json => {
        defaultCatchValidation(json, navigate);
      })
      .finally(() => {
        setCreatingTask(false);
      });
  };
  const handleNewTaskCancel = () => {
    if (creatingTask) {return;}
    setIsVisibleNewTaskModal(false);
  };

  useEffect(() => {
    if (isNaN(actionPlanID) || actionPlanID <= 0 ) {
      navigate(AppRoutes.Forbidden);
      return;
    }

    if (session.accessToken === undefined) {
      navigate(AppRoutes.Logout);
      return;
    }

    const getData = () => {
      setLoading(true);

      ac.abort();
      ac = new AbortController();

      getActionPlan(ac, session.accessToken, actionPlanID)
        .then(json => {
          setActionPlan(json.actionPlan);
          setComments(json.comments);
          const userList = json.assignableUsers.sort((a, b) => ("" + a.name + a.username).localeCompare(""+ b.name + b.username))
          setAssignableUsers(userList);
          setHasAssignableUsers(json.hasAssignableUsers);
          const idxDefaultUser = userList.findIndex(e => e.id === json.defaultAssignableUser)
          if (idxDefaultUser >= 0) {setDefaultAssignableUser(json.defaultAssignableUser)}
          fillTable(json.tasks);
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setLoading(false);
        });


      setIsLoadingTags(true);
      getCustomerTags(session.accessToken)
        .then(({ tags }) => {
          if (Array.isArray(tags) && tags.length > 0) {
            setShowTagFeature(true);

            setTagOptions(tags.map(({ id, title }) => ({
              value: id,
              label: title,
            })));
          }
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setIsLoadingTags(false);
        });
    };

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillTable = tasks => {
    const data = [];
    tasks.forEach((element) => {
      const obj = {
        key: element.id,
        actionPlanID: element.actionPlanID,
        info: {
          tID: element.id,
          apID: element.actionPlanID,
          status: element.status,
          title: element.title
        },
        createdFullname: element.createdFullname,
        createdDate: element.createdDate,
        assignedFullname: element.assignedFullname,
        observations: element.observations,
        attachmentsCount: element.attachmentsCount,
      };
      data.push(obj);
    });

    const columns = [
      {
        title: "Tarea",
        dataIndex: "info",
        key: "info",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => ("" + a.info.title).localeCompare(""+ b.info.title),
        render: info => (
          <span className="has-min-width">
            {<FontAwesomeIcon icon={info.status ? faCheckSquare : faSquare} />}
            &nbsp;
            <Typography.Link
              className="label-data"
              href={ResolvedAppRoutes.Task({ actionPlanID: info.apID, taskID: info.tID })}
            >
              {info.title}
            </Typography.Link>
          </span>
        ),
      },
      {
        title: "Creada por",
        dataIndex: "createdFullname",
        key: "createdFullname",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => ("" + a.createdFullname).localeCompare(""+ b.createdFullname),
        render: createdFullname => (
          <span className="label-data has-min-width">{createdFullname}</span>
        ),
      },
      {
        title: "Fecha de creación",
        dataIndex: "createdDate",
        key: "createdDate",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => ("" + a.createdDate).localeCompare(''+ b.createdDate),
        render: createdDate => (
          <span className="label-data has-min-width">{dayjs(createdDate).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Asignado a",
        dataIndex: "assignedFullname",
        key: "assignedFullname",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => ("" + a.assignedFullname).localeCompare(''+ b.assignedFullname),
        render: assignedFullname => (
          <span className="label-data has-min-width">{assignedFullname}</span>
        ),
      },
      {
        title: "Observaciones",
        dataIndex: "observations",
        key: "observations",
        render: observations => (
          <span className="label-data has-min-width">{observations}</span>
        ),
      },
      {
        title: "Nº Adjuntos",
        dataIndex: "attachmentsCount",
        key: "attachmentsCount",
        render: attachmentsCount => (
          <span className="label-data">{attachmentsCount}</span>
        ),
      },
    ];

    setTableData(data);
    setTableColumns(columns);
  };

  const sendMessage = data => {
    if (sendingMessage) {
      return;
    }
    if (data.message) {
      setSendingMessage(true);
      newComment(session.accessToken, actionPlanID, data.message)
      .then(json => {
        setComments(json.comments);
        reset({message: ""});
      })
      .catch(json => {
        defaultCatchValidation(json, navigate);
      })
      .finally(() => {
        setSendingMessage(false);
      });
    }
  };

  return (
    <div>
      {(loading ? (
        <LoadSpinner />
      ):(
        <div className="weglot-translate">
          <Breadcrumb
            className="is-breadcrumb"
            separator=">"
            items={[
              { href: AppRoutes.HomePage, title: (<HomeFilled/>) },
              { href: AppRoutes.ActionPlanList, title: (<strong>Planes de acción</strong>) },
              { title: (<label>Plan de acción actual</label>) },
            ]}
          />

          <div className="box">
              <div className="columns">
                <div className="column">
                  <p className="pb-2">{actionPlan.scopeName}</p>
                  <label><h4>{actionPlan.title}</h4></label>
                </div>
                <div className="column is-narrow has-text-right-desktop">
                  <Button className="fixed-margin-horizontal" icon={<DeleteOutlined/>} onClick={showDeleteModal}>Eliminar</Button>
                  <Button className="fixed-margin-horizontal" icon={<EditFilled />} onClick={showEditModal}>Editar</Button>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <label className="label-data">{actionPlan.description}</label>
                </div>
              </div>

              <div className="columns is-narrow">
                <div className="column is-3">
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Estado: `}</label>
                    <label className={"label-data"} style={{color: getActionPlanStatusData(actionPlan.status).color}}>{getActionPlanStatusData(actionPlan.status).text}</label>
                  </div>
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Prioridad: `}</label>
                    <label className={"label-data"} style={{color: getActionPlanPriorityData(actionPlan.priority).color}}>{getActionPlanPriorityData(actionPlan.priority).text}</label>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Fecha de inicio: `}</label>
                    <label className="label-data">{dayjs(actionPlan.startDate).format("DD/MM/YYYY")}</label>
                  </div>
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Fecha de cierre: `}</label>
                    <div className="is-flex  is-flex-wrap-wrap align-bottom">
                      <label className="label-data">{dayjs(actionPlan.deadlineDate).format("DD/MM/YYYY")}</label>
                      {actionPlan.expired && (<Tag className="ml-2 mb-1" color="error">Expirado</Tag>)}
                    </div>
                    
                  </div>
                </div>
                <div className="column is-3">
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Avance del plan: `}</label>
                    <ProgressBar percent={actionPlan.tasksProgress}/>
                  </div>
                  <div className="is-flex  is-flex-wrap-wrap">
                    <label className="label-title">{`Tiempo de avance: `}</label>
                    <ProgressBar percent={actionPlan.dateProgress}/>
                  </div>
                </div>
                <div className="column is-3">
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Desempeño inicial: `}</label>
                    <ProgressBar percent={actionPlan.initialScore} indicator="attribute" />
                  </div>
                  <div className="is-flex is-flex-wrap-wrap">
                    <label className="label-title">{`Desempeño actual: `}</label>
                    <ProgressBar percent={actionPlan.currentScore} indicator="attribute" />
                  </div>
                </div>
              </div>

              {Array.isArray(actionPlan.tags) && actionPlan.tags.length > 0 && (
                <div className="mb-4">
                  <label className="label-title">Tags:</label>
                  <Flex gap={12} wrap>
                    {actionPlan.tags.map(({ id, title }) => (
                      <Tag key={id}>{title}</Tag>
                    ))}
                  </Flex>
                </div>
              )}


              {actionPlan.reason && (
                <Comment
                  className="is-summary-label"
                  author="Razon Raíz: "
                  content={(<p>{actionPlan.reason}</p>)}
                />
              )}
              {actionPlan.resource && (
                <Comment
                  className="is-summary-label"
                  author="Recurso: "
                  content={(<p>{actionPlan.resource}</p>)}
                />
              )}

          </div>

          <div className="box">

            <div className="columns">
              <div className="column is-narrow">
                <label><h5>{`Tareas (${tableData.length})`}</h5></label>
              </div>
              <div className="column has-text-right-desktop">
                <Button icon={<PlusOutlined />} onClick={showNewTaskModal}>Agregar tarea</Button>
              </div>
            </div>
            <Table
              size="small"
              showSorterTooltip={false}
              dataSource={tableData}
              columns={tableColumns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>

          <div className="box">
            <label><h5>{`Comentarios y logs (${comments.length})`}</h5></label>
              <form onSubmit={handleSubmit(sendMessage)}>
                <div className="field">
                  <div className="control">
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <Input.TextArea
                          style={{ minHeight: 120, maxHeight: 520, resize: 'vertical' }}
                          classNames={classNamesInputTextarea}
                          placeholder="Agregar comentario ..."
                          autoFocus=""
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <button
                  className={`button is-block is-small is-info ${sendingMessage ? "is-loading" : ""}`}
                  type="submit"
                >{"Enviar"}</button>
              </form>

            {comments.map((element) => (
              <Comment
                key={element.id}
                author={<label>{element.isEvent ? `${element.createdFullname} ${element.message}` : `${element.createdFullname}`}</label>}
                datetime={
                  <Tooltip placement="bottom" title={dayjs(element.createdDate).format("DD/MM/YYYY HH:mm:ss")}>
                    <label>{dayjs(element.createdDate).fromNow()}</label>
                  </Tooltip>
                }
                content={element.isEvent ? null : (<p>{element.message}</p>)}
              />
            ))}
          </div>
        </div>
      ))}

      
      <div className="weglot-translate">
        <Modal
          title="Editar plan de acción"
          classNames={classNamesModal}
          open={isVisibleEditModal}
          closable={false}
          okText="Guardar"
          cancelText="Cancelar"
          onOk={handleEditSave}
          onCancel={handleEditCancel}
        >
          <Spin tip="Guardando cambios" size="large" spinning={updatingActionPlan}>
            <div className="field weglot-translate">
              <div className="control">
                <label>Descripción breve:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="100"
                  placeholder="Ingrese descripción breve.."
                  value={editDescription}
                  onChange={event => {
                    setEditDescription(event.target.value);
                    if (event.target.value) {
                      setEditDescriptionRequired(false);
                    } else {
                      setEditDescriptionRequired(true);
                    }
                  }}
                  />
                  {editDescriptionRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Fecha de inicio y final:</label><br/>
                <RangePicker
                  style={{ width: '100%' }}
                  size="large"
                  format="DD/MM/YYYY"
                  value={[editStartDate, editDeadlineDate]}
                  onChange={arrayData => {
                    if (Array.isArray(arrayData) && arrayData.length === 2) {
                      setEditStartDate(arrayData[0]);
                      setEditDeadlineDate(arrayData[1]);
                    } else {
                      setEditStartDate(dayjs());
                      setEditDeadlineDate(dayjs());
                    }
                  }}
                />
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Prioridad:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={editPriority}
                    onChange={value => setEditPriority(value)}
                    options={[
                      { value: "0", label: getActionPlanPriorityData(0).text },
                      { value: "1", label: getActionPlanPriorityData(1).text },
                      { value: "2", label: getActionPlanPriorityData(2).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Estado:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={editStatus}
                    onChange={value => setEditStatus(value)}
                    options={[
                      { value: "false", label: getActionPlanStatusData(false).text },
                      { value: "true", label: getActionPlanStatusData(true).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            {showTagFeature && (
              <Spin size="small" spinning={isLoadingTags}>
                <div className="field weglot-translate">
                  <div className="control">
                    <label>Tags:</label><br/>
                    <div>
                      <Select
                        style={{ width: '100%' }}
                        size="large"
                        mode="multiple"
                        placeholder="(opcional) Tags relacionados al plan ..."
                        value={editTags}
                        onChange={value => setEditTags(value)}
                        options={tagOptions}
                        notFoundContent="No hay tags disponibles"
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            )}
            <div className="field weglot-translate">
              <div className="control">
                <label>Causa Raíz:</label><br/>
                <Input.TextArea
                  style={{ height: 110, resize: 'none' }}
                  classNames={classNamesInputTextarea}
                  size="large"
                  placeholder="(opcional) Descripción de la causa ..."
                  value={editReason}
                  onChange={event => {
                    setEditReason(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Recurso:</label><br/>
                <Input.TextArea
                  style={{ height: 110, resize: 'none' }}
                  size="large"
                  classNames={classNamesInputTextarea}
                  placeholder="(opcional) Descripción del recurso ..."
                  value={editResource}
                  onChange={event => {
                    setEditResource(event.target.value);
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>

      <div className="weglot-translate">
        <Modal
          title="Eliminar plan de acción"
          classNames={classNamesModal}
          open={isVisibleDeleteModal}
          closable={false}
          okText="Si"
          cancelText="No"
          onOk={handleDeleteYes}
          onCancel={handleDeleteNo}
        >
          <Spin tip="Guardando cambios" size="large" spinning={deletingActionPlan}>
            <div>
              <label>¿ Está seguro que desea eliminar el plan de acción de forma permanente ?</label>
            </div>
          </Spin>
        </Modal>
      </div>

      <div>
        <Modal
          title="Nueva tarea"
          classNames={classNamesModal}
          open={isVisibleNewTaskModal}
          closable={false}
          okText="Crear"
          cancelText="Cancelar"
          onOk={handleNewTaskSave}
          onCancel={handleNewTaskCancel}
        >
          <Spin tip="Procesando" size="large" spinning={creatingTask}>
            <div className="field weglot-translate">
              <div className="control">
                <label>Título:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="50"
                  placeholder="Ingrese título.."
                  value={newTaskTitle}
                  onChange={event => {
                    setNewTaskTitle(event.target.value);
                    setNewTaskTitleRequired(false);
                  }}
                />
                  {newTaskTitleRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Asignado a:</label><br/>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    size="large"
                    placeholder="Seleccione usuario"
                    optionFilterProp="children"
                    value={newTaskAssignedTo}
                    onChange={value => {
                      setNewTaskAssignedTo(value);
                      setNewTaskAssignedToRequired(false);
                    }}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    notFoundContent={(null)}
                    options={assignableUsers.map(user => ({
                      value: user.id,
                      label: `${user.name} (${user.username})`,
                    }))}
                  />
                  {!hasAssignableUsers && (
                    <label className="has-text-danger">
                      * No se encontraron usuarios asignables para las tareas.
                    </label>
                  )}
                  {newTaskAssignedToRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Estado:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={newTaskStatus}
                    onChange={value => setNewTaskStatus(value)}
                    options={[
                      { value: "false", label: getTaskStatusData(false).text },
                      { value: "true", label: getTaskStatusData(true).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Observaciones:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="150"
                  placeholder="(opcional) Observaciones de la tarea ..."
                  value={newTaskObservations}
                  onChange={event => {
                    setNewTaskObservations(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="field weglot-translate">
              <div className="control">
                <label>Descripción detallada:</label><br/>
                <Input.TextArea
                  style={{ height: 220, resize: 'none' }}
                  classNames={classNamesInputTextarea}
                  placeholder="(opcional) Descripción detallada de la tarea ..."
                  value={newTaskTextDetails}
                  onChange={event => {
                    setNewTaskTextDetails(event.target.value);
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};
