import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import { defaultCatchValidation, login, newDeprecatedActionPlan } from "../services/task-tracking.js";
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { AppRoutes, ResolvedAppRoutes } from "../utils/app-routes.js";
import { getSessionScopeVars } from "../utils/fns.js";

export default function DeprecatedNewPlanScreen() {
  const navigate = useNavigate();
  const { userToken, customerCode, shopID, projectID, dimensionNumber, attributeNumber, initialScore } = useParams();

  const { setSession, updateSession, cleanSession } = useContext(GlobalContext);

  useEffect(() => {
    if (
      isNaN(shopID) || shopID <= 0 ||
      isNaN(projectID) || projectID <= 0 ||
      isNaN(dimensionNumber) || dimensionNumber < 0 ||
      isNaN(attributeNumber) || attributeNumber < 0 ||
      isNaN(initialScore) || initialScore < 0
    ) {
      navigate(AppRoutes.Forbidden);
      return;
    }
    cleanSession();
    login(userToken, customerCode)
    .then(json => {
      // Set user data in context
      setSession({
        accessToken: json.accessToken,
        userToken: userToken,
        userName: json.user.name,
        userEmail: json.user.email,
        companyName: json.customer.companyName,
        customerName: json.customer.name,
        customerLogo: json.customer.logo,
        qsScopeType: '',
        qsScopeKeys: '',
        qsArray: null,
        more: json.more,
      });

      // Create action-plan (deprecated way)
      newDeprecatedActionPlan(
        json.accessToken,
        Number(projectID),
        Number(shopID),
        Number(dimensionNumber),
        Number(attributeNumber),
        Number(initialScore),
      )
      .then(json => {
        updateSession(getSessionScopeVars(json.scopeMetadata));
        navigate(ResolvedAppRoutes.ActionPlan({ actionPlanID: json.actionPlanID }));
      })
      .catch(json => {
        defaultCatchValidation(json, navigate);
      });
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadSpinner />
  );
};
