import React from "react";
import { bool, string } from "prop-types";
import { Spin } from "antd";

export default function LoadSpinner({ isLoading, tip, errorMsg }) {
  return (
    <div className="loading-block weglot-translate">
      {errorMsg ? (
      <label>{errorMsg}</label>
      ) : (
        <Spin spinning={isLoading} tip={tip} size="large">
          <div></div>
        </Spin>
      )}
    </div>
  );
};

LoadSpinner.propTypes = {
  isLoading: bool,
  tip: string,
  errorMsg: string,
};

LoadSpinner.defaultProps = {
  isLoading: true,
  tip: 'Obteniendo información',
  errorMsg: '',
};
