import React from "react";

export default function MaintenanceScreen() {

  return (
    <div className="loading-block weglot-translate">
      <label>{"En estos momentos el servidor se encuentra en mantenimiento."}</label>
    </div>
  );
};
