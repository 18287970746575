import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import { defaultCatchValidation as dcvTT, login } from "../services/task-tracking";
import { getSessionScopeVars } from "../utils/fns.js";
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { AppRoutes } from "../utils/app-routes.js";

export default function LoginScreen() {
  const navigate = useNavigate();

  const { userToken, customerCode } = useParams();

  const [searchParams] = useSearchParams();

  const { setSession, cleanSession, updateSession } = useContext(GlobalContext);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    cleanSession();

    const {
      countryCode,
      stateCode,
      regionCode,
      zoneCode,
      cityCode,
      shopID,
      shopTypeCode,
      businessTypeCode,
    } = Object.fromEntries([...searchParams]);
  
    const qs = [];
    if (countryCode !== undefined) { qs.push({ id:'countryCode', value: countryCode }); }
    if (stateCode !== undefined) { qs.push({ id:'stateCode', value: stateCode }); }
    if (regionCode !== undefined) { qs.push({ id:'regionCode', value: regionCode }); }
    if (zoneCode !== undefined) { qs.push({ id:'zoneCode', value: zoneCode }); }
    if (cityCode !== undefined) { qs.push({ id:'cityCode', value: cityCode }); }
    if (shopID !== undefined) { qs.push({ id:'shopID', value: shopID }); }
    if (shopTypeCode !== undefined) { qs.push({ id:'shopTypeCode', value: shopTypeCode }); }
    if (businessTypeCode !== undefined) { qs.push({ id:'businessTypeCode', value: businessTypeCode }); }

    login(userToken, customerCode, qs)
    .then(json => {

      // Set user data in context
      setSession({
        accessToken: json.accessToken,
        userToken: userToken,
        userName: json.user.name,
        userEmail: json.user.email,
        companyName: json.customer.companyName,
        customerName: json.customer.name,
        customerLogo: json.customer.logo,
        qsScopeType: '',
        qsScopeKeys: '',
        qsArray: null,
        more: json.more,
      });

      if (json.scopeMetadata) {
        // When scopeMetadata has data: update scope vars and redirect to action plans list
        updateSession(getSessionScopeVars(json.scopeMetadata));
        navigate(AppRoutes.ActionPlanList);
      }
      else {
        // Redirect to main page
        navigate(AppRoutes.HomePage);
      }
    })
    .catch(json => {
      dcvTT(json, navigate);
      setLoading(false)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadSpinner />
  ):(
    <div className="loading-block weglot-translate">
      <label>{"Datos de acceso inválidos."}</label>
    </div>
  );
};
