import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { ResolvedAppRoutes } from "../utils/app-routes.js";

export default function DeprecatedLoginScreen() {
  const navigate = useNavigate();

  const { userToken, customerCode, shopID } = useParams();

  useEffect(() => {
      // Redirect to current login
      navigate({
        pathname: ResolvedAppRoutes.Login({ userToken, customerCode }),
        search: `?shopID=${shopID}`, // This cause redirection to shop scoped ActionPlansScreen instead SummaryActionPlansScreen
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<LoadSpinner />);
};
