import * as Cookies from "js-cookie";

const CookieSessionKey = "session"
export const getSessionCookie = () => {
  const sessionCookie = Cookies.get(CookieSessionKey);

  if (sessionCookie === undefined) {
    return {};
  } else {
    return JSON.parse(sessionCookie);
  }
};

export const setSessionCookie = (session) => {
  const data = {
    accessToken: session.accessToken,
    userToken: session.userToken,
    userName: session.userName,
    userEmail: session.userEmail,
    companyName: session.companyName,
    customerName: session.customerName,
    customerLogo: session.customerLogo,
    scopeFilterName: session.scopeFilterName,
    scopeFilterQS: session.scopeFilterQS,
    qsScopeType: session.qsScopeType,
    qsScopeKeys: session.qsScopeKeys,
    qsArray: session.qsArray,
    more: session.more,
  }

  Cookies.set(CookieSessionKey, JSON.stringify(data));
};


export const updateSessionCookie = (partialSession) => {

  const data = {
    ...getSessionCookie(),
    ...partialSession,
  }

  Cookies.set(CookieSessionKey, JSON.stringify(data));
};

export const cleanSessionCookie = () => {
  Cookies.remove(CookieSessionKey);
};

