import React from "react";
import { node, number } from "prop-types";
import { Statistic, Typography } from "antd";
const { Text, Title } = Typography;

export default function ProgressCard({ title, current, total, percentage, minWidth }) {
  if (current === undefined) { return null; }

  return (
    <Statistic
      style={{ minWidth: minWidth }}
      title={title}
      value={current}
      suffix={(
        <div style={{
          display: 'inline-flex',
          gap: 5,
          alignItems: 'flex-end',
        }}>
          {total !== undefined && (<Title className="m-1" level={4}>{`/ ${total}`}</Title>)}
          {percentage !== undefined && (<Text className="m-1">{`${percentage}%`}</Text>)}
        </div>
      )}
    />
  );
};

ProgressCard.propTypes = {
  title: node,
  current: number,
  total: number,
  percentage: number,
  minWidth: number,
};

ProgressCard.defaultProps = {
  title: undefined,
  current: undefined,
  total: undefined,
  percentage: undefined,
  minWidth: undefined,
};
