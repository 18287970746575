import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import { defaultCatchValidation, getSummaryActionPlans } from "../services/task-tracking.js";
import { Breadcrumb, Button, Table, Tooltip } from "antd";
import { HomeFilled } from '@ant-design/icons';
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { getSessionScopeVars, getTreeKeys } from "../utils/fns.js";
import ExpandCollapseButton from "./shared/ExpandCollapseButton.jsx";
import { AppRoutes } from "../utils/app-routes.js";

let ac = new AbortController();

export default function SummaryActionPlansScreen() {
  const navigate = useNavigate();
  const { session, updateSession } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  
  const [rowKeys, setRowKeys] = useState([]);
  const [expRowKeys, setExpRowKeys] = useState([]);

  useEffect(() => {
    if (session.accessToken === undefined) {
      navigate(AppRoutes.Logout);
      return;
    }

    updateSession({
      qsScopeType: '',
      qsScopeKeys: '',
      qsArray: null,
    });

    const getData = () => {
      setLoading(true);

      ac.abort();
      ac = new AbortController();

      getSummaryActionPlans(ac, session.accessToken)
        .then(json => {
          const summaryTreeRowKeys = getTreeKeys(json.summaryTree);
          setRowKeys(summaryTreeRowKeys);
          setExpRowKeys(summaryTreeRowKeys); // Expand all by default
          setTableData(json.summaryTree);
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: (
        <>
          <ExpandCollapseButton
            availableExpand={expRowKeys.length < rowKeys.length}
            onExpand={() => { setExpRowKeys(rowKeys); }}
            expandText="Desplegar todo"
            onCollapse={() => { setExpRowKeys([]); }}
            collapseText="Reducir todo"
          />
          Alcance del plan
        </>
      ),
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => (`${a.name}`).localeCompare(`${b.name}`),
      render: (name, record) => {
        const title = `${record.sortWeight > 1 ? 'Tipo de alcance: ' : ''}${name}`;
        return (
          <Button
            className={`py-0 px-2 ${record.sortWeight > 1 ? 'has-text-weight-bold' : 'is-italic' }`}
            size="small"
            type="link"
            shape="rounded"
            onClick={() => {            
              updateSession(getSessionScopeVars(record.scopeMetadata));
              navigate(AppRoutes.ActionPlanList);
            }}
          >
            {record.sortWeight === 0 ? (
              <Tooltip title={record.scopeMetadata.scopeKeysLabel} placement="right">
                {title}
              </Tooltip>
            ) : (
              <>{title}</>
            )}
          </Button>
        );
      },
    },
    {
      title: "Total",
      dataIndex: ["summary", "total"],
      key: "total",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => (`${a.summary.total}`).localeCompare(`${b.summary.total}`),
      render: total => (
        <label>
          {total}
        </label>
      ),
    },
    {
      title: "No expirados",
      dataIndex: ["summary", "active"],
      key: "active",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => (`${a.summary.active.current}`).localeCompare(`${b.summary.active.current}`),
      render: ({ current, total, percentage}) => (
        <label>
          {`${current} (${percentage}%)`}
        </label>
      ),
    },
    {
      title: "Expirados",
      dataIndex: ["summary", "expired"],
      key: "expired",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => (`${a.summary.expired.current}`).localeCompare(`${b.summary.expired.current}`),
      render: ({ current, total, percentage}) => (
        <label>
          {`${current} (${percentage}%)`}
        </label>
      ),
    },
    {
      title: "Cerrados",
      dataIndex: ["summary", "closed"],
      key: "closed",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => (`${a.summary.closed.current}`).localeCompare(`${b.summary.closed.current}`),
      render: ({ current, total, percentage}) => (
        <label>
          {`${current} (${percentage}%)`}
        </label>
      ),
    },
  ];

  return (
    <div className="action-plan-list-screen-container weglot-translate">
      {(loading ? (
        <LoadSpinner />
      ):(
        <div>
          <Breadcrumb
            className="is-breadcrumb"
            separator=">"
            items={[
              { title: (<HomeFilled/>) },
            ]}
          />
          <div className="box break-on-print">
            <label><h4>Resumen de Planes de acción</h4></label>
            <Table
              size="small"
              showSorterTooltip={false}
              expandable={{
                expandedRowKeys: expRowKeys,
                onExpandedRowsChange: (expRows) => { setExpRowKeys(expRows); },
              }}
              dataSource={tableData}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>    
        </div>
      ))}
    </div>
  );
};
