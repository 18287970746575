import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import ProgressBar from "./shared/ProgressBar.jsx";
import StatusSummary from "./shared/StatusSummary.jsx";
import ExportActionPlans from "./shared/ExportActionPlans.jsx";
import InfoModal from "./shared/InfoModal.jsx";

import { defaultCatchValidation, getActionPlanList, getCustomerTags } from "../services/task-tracking";
import dayjs from "dayjs";
import { Breadcrumb, Divider, Flex, Input, Radio, Select, Spin, Table, Tag, Typography } from "antd";
import { Comment } from "@ant-design/compatible";
import { HomeFilled } from '@ant-design/icons';
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { AppRoutes, ResolvedAppRoutes } from "../utils/app-routes.js";

let ac = new AbortController();

const PriorityFilterOptionAll = Object.freeze({ label: 'Todos', value: '' });

const StatusFilter = Object.freeze({
  All: { label: 'Todos', value: '' },
  ActiveNotExpired: { label: 'No expirados', value: 'activeNotExpired' },
  ActiveExpired: { label: 'Expirados', value: 'activeExpired' },
  Closed: { label: 'Cerrados', value: 'closed' },
});

const ProjectFilterOptionAll = Object.freeze({ label: 'Todos', value: '' });

const TagFilterOptionAll = Object.freeze({ label: 'Todos', value: '' });

export default function ActionPlanListScreen() {
  const navigate = useNavigate();
  const { session, getActionPlanPriorityData } = useContext(GlobalContext);

  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [showTagFeature, setShowTagFeature] = useState(false);

  const [loading, setLoading] = useState(true);

  const [summary, setSummary] = useState([]);
  const [actionPlans, setActionPlans] = useState([]);

  const [tableActiveData, setTableActiveData] = useState([]);
  const [tableClosedData, setTableClosedData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const [priorityFilter, setPriorityFilter] = useState(PriorityFilterOptionAll.value);
  const [statusFilter, setStatusFilter] = useState(StatusFilter.All.value);
  const [projectFilterOptions, setProjectFilterOptions] = useState([ProjectFilterOptionAll]);
  const [projectFilter, setProjectFilter] = useState(ProjectFilterOptionAll.value);
  const [wordFilter, setWordFilter] = useState('');
  const [tagFilterOptions, setTagFilterOptions] = useState([TagFilterOptionAll]);
  const [tagFilter, setTagFilter] = useState(TagFilterOptionAll.value);

  useEffect(() => {
    if (session.accessToken === undefined) {
      navigate(AppRoutes.Logout);
      return;
    }

    const getData = () => {
      setLoading(true);

      ac.abort();
      ac = new AbortController();

      getActionPlanList(ac, session.accessToken, session.qsArray)
        .then(json => {
          setActionPlans(json.actionPlans);
          /*
           * La seccion Summary ahora se calculan en el FE pasando por los filtros de busqueda.
           * setSummary(json.summary);
           */
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setLoading(false);
        });


      setIsLoadingTags(true);
      getCustomerTags(session.accessToken)
        .then(({ tags }) => {
          if (Array.isArray(tags) && tags.length > 0) {
            setShowTagFeature(true);

            setTagFilterOptions([
              TagFilterOptionAll,
              ...tags.map(({ id, title }) => ({
                value: id,
                label: title,
              })),
            ]);
          }
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setIsLoadingTags(false);
        });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { // Fill tables
    const activeData = [];
    const closedData = [];
    let total = 0;
    let activeNotExpiredTotal = 0;
    let activeExpiredTotal = 0;
    let closedTotal = 0;

    actionPlans.forEach((element) => {
      let filterMatch = true

      if (priorityFilter !== PriorityFilterOptionAll.value) {
        // Filter by priority
        filterMatch = priorityFilter === `${element.priority}`;
      }

      if (filterMatch) {
        // Filter by status
        switch (statusFilter) {
          case StatusFilter.ActiveNotExpired.value: {
            if (element.status || element.expired) { filterMatch = false; }
            break;
          } 
          case StatusFilter.ActiveExpired.value: {
            if (element.status || !element.expired) { filterMatch = false; }
            break;
          } 
          case StatusFilter.Closed.value: {
            if (!element.status) { filterMatch = false; }
            break;
          } 
          case StatusFilter.All.value:
          default:
            break;
        }
      }

      if (filterMatch) {
        // Filter by original project
        if (![ProjectFilterOptionAll.value, element.projectID].includes(projectFilter)) {
          filterMatch = false;
        }
      }

      if (filterMatch && wordFilter) {
        // Filter by word
        if (
          element.title.toLowerCase().indexOf(wordFilter.toLowerCase()) < 0
          && element.description.toLowerCase().indexOf(wordFilter.toLowerCase()) < 0
          && element.createdFullname.toLowerCase().indexOf(wordFilter.toLowerCase()) < 0
        ) {
          filterMatch = false;
        }
        
      }

      if (filterMatch) {
        // Filter by tag
        if (tagFilter !== TagFilterOptionAll.value) {
          filterMatch = Array.isArray(element.tags)
          && element.tags.map(({ id }) => id).includes(tagFilter)
        }
      }

      if (filterMatch) {
        const obj = {
          key: element.id,
          scopeName: element.scopeName,
          name: {
            apID: element.id,
            title: element.title,
            description: element.description,
            priority: element.priority,
            reason: element.reason,
            resource: element.resource,
          },
          initialScore: element.initialScore,
          startDate: element.startDate,
          deadline: { date: element.deadlineDate, expired: element.expired },
          tasksProgress: element.tasksProgress,
          priority: element.priority,
          status: element.status,
          createdFullname: element.createdFullname,
          originalProjectName: element.originalProjectName,
          tags: Array.isArray(element.tags) ? element.tags : [],
        };
        if (element.status) {
          closedData.push(obj)
          total += 1;
          closedTotal += 1;
        } else {
          activeData.push(obj);
          total += 1;
          if (element.expired) {
            activeExpiredTotal += 1;
          } else {
            activeNotExpiredTotal += 1;
          }
        }
      }
    });

    const summary = { total };
    const getSummaryField = (total, subtotal) => ({
      current: subtotal,
      total: total,
      percentage: total
        ? Math.round(((subtotal * 100 / total) + Number.EPSILON) * 10) / 10
        : 0,
    });

    if ([StatusFilter.All.value, StatusFilter.ActiveNotExpired.value].includes(statusFilter)) {
      summary.active = getSummaryField(total, activeNotExpiredTotal);
    }
    if ([StatusFilter.All.value, StatusFilter.ActiveExpired.value].includes(statusFilter)) {
      summary.expired = getSummaryField(total, activeExpiredTotal);
    }
    if ([StatusFilter.All.value, StatusFilter.Closed.value].includes(statusFilter)) {
      summary.closed = getSummaryField(total, closedTotal);
    }

    const columns = [
      {
        title: "Plan de acción",
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.name.description}${a.name.title}`).localeCompare(`${b.name.description}${b.name.title}`),
        render: name => (
          <div style={{display:'flex'}}>
            <div style={{ width: 8, minWidth: 8, backgroundColor: getActionPlanPriorityData(name.priority).color}}></div>
            <div className="has-min-width-large" style={{ marginLeft: '5px' }}>
              <Typography.Link className="label-data" href={ResolvedAppRoutes.ActionPlan({ actionPlanID: name.apID })}>
                {name.description}
              </Typography.Link>
              <br />
              <span>{name.title}</span>
              {(name.reason || name.resource) && (
                <>
                  <br />
                  <InfoModal
                    title={(<div className="p-3">{name.description}</div>)}
                    body={(
                      <div className="p-3">
                        {name.reason && (
                          <Comment
                            className="is-summary-label"
                            author="Razon Raíz: "
                            content={(<p>{name.reason}</p>)}
                          />
                        )}
                        {name.resource && (
                          <Comment
                            className="is-summary-label"
                            author="Recurso: "
                            content={(<p>{name.resource}</p>)}
                          />
                        )}
                      </div>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Alcance del plan",
        dataIndex: "scopeName",
        key: "scopeName",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.scopeName}`).localeCompare(`${b.scopeName}`),
        render: scopeName => (
          <span className="label-data has-min-width-large">
            {scopeName}
          </span>
        ),
      },
      {
        title: "Prioridad",
        dataIndex: "priority",
        key: "priority",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.priority}`).localeCompare(`${b.priority}`),
        render: priority => (
          <span className="label-data" style={{color: getActionPlanPriorityData(priority).color}}>
            {getActionPlanPriorityData(priority).text}
          </span>
        ),
      },
      {
        title: "Avance del plan",
        dataIndex: "tasksProgress",
        key: "tasksProgress",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.tasksProgress - b.tasksProgress,
        render: tasksProgress => (
          <div className="has-min-width"><ProgressBar percent={tasksProgress}/></div>
        ),
      },
      {
        title: "Fecha de inicio",
        dataIndex: "startDate",
        key: "startDate",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.startDate}`).localeCompare(`${b.startDate}`),
        render: startDate => (
          <span className="label-data">{dayjs(startDate).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Fecha de vencimiento",
        dataIndex: "deadline",
        key: "deadline",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.deadline.date}`).localeCompare(`${b.deadline.date}`),
        render: ({date, expired}) => (
          <>
            <span className="label-data">{dayjs(date).format("DD/MM/YYYY")}</span>
            {expired && (<Tag color="error">Expirado</Tag>)}
          </>
          
        ),
      },
      {
        title: "Desempeño inicial",
        dataIndex: "initialScore",
        key: "initialScore",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.initialScore - b.initialScore,
        render: initialScore => (
          <div className="has-min-width"><ProgressBar percent={initialScore} indicator="attribute" /></div>
        ),
      },
      /* FIXME: Descomentar cuando quieran desempeño actual en la lista
      {
        title: "Desempeño actual",
        dataIndex: "currentScore",
        key: "currentScore",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.currentScore}`).localeCompare(`${b.currentScore}`),
        render: currentScore => (
          <div className="has-min-width"><ProgressBar percent={currentScore} indicator="attribute" /></div>
        ),
      },
      */
      {
        title: "Creado por",
        dataIndex: "createdFullname",
        key: "createdFullname",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.createdFullname}`).localeCompare(`${b.createdFullname}`),
        render: createdFullname => (
          <span className="label-data">
            {createdFullname}
          </span>
        ),
      },
      {
        title: "Medición donde se creó",
        dataIndex: "originalProjectName",
        key: "originalProjectName",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => (`${a.originalProjectName}`).localeCompare(`${b.originalProjectName}`),
        render: originalProjectName => (
          <span className="label-data has-min-width-large">
            {originalProjectName}
          </span>
        ),
      },
      ...(showTagFeature ? [{
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          render: tags => (
            <Flex gap={12} wrap>
              {tags.map(({ id, title }) => (
                <Tag key={id}>{title}</Tag>
              ))}
            </Flex>
          ),
        }] : []
      ),
    ];

    setSummary(summary);
    setTableActiveData(activeData);
    setTableClosedData(closedData);
    setTableColumns(columns);
  }, [
    getActionPlanPriorityData,
    actionPlans,
    priorityFilter,
    statusFilter,
    projectFilter,
    wordFilter,
    tagFilter,
    showTagFeature,
  ]);


  useEffect(() => { // Fill project filter options
    const optionMap = {};
    actionPlans.forEach(({ projectID, originalProjectName }) => {
      optionMap[projectID] = { value: projectID, label: originalProjectName};
    });

    const options = [
      ProjectFilterOptionAll,
      ...Object.values(optionMap).sort((a, b) => (`${b.value}`).localeCompare(`${a.value}`)),
    ];
    setProjectFilterOptions(options);
  }, [actionPlans]);

  return (
    <div className="action-plan-list-screen-container weglot-translate">
      {(loading ? (
        <LoadSpinner />
      ):(
        <div>
          <Breadcrumb
            className="is-breadcrumb"
            separator=">"
            items={[
              { href: AppRoutes.HomePage, title: (<HomeFilled/>) },
              { title: (<label>Planes de acción</label>) },
            ]}
          />

          <div className="box break-on-print">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
              <div>
                <Typography.Text strong className="is-block">Prioridad:</Typography.Text>
                <Radio.Group value={priorityFilter} onChange={(e) => { setPriorityFilter(e.target.value); }}>
                  <Radio.Button value={PriorityFilterOptionAll.value}>{PriorityFilterOptionAll.label}</Radio.Button>
                  <Radio.Button value="0">{getActionPlanPriorityData(0).text}</Radio.Button>
                  <Radio.Button value="1">{getActionPlanPriorityData(1).text}</Radio.Button>
                  <Radio.Button value="2">{getActionPlanPriorityData(2).text}</Radio.Button>
                </Radio.Group>
              </div>
              <div>
                <Typography.Text strong className="is-block">Estado:</Typography.Text>
                <Radio.Group value={statusFilter} onChange={(e) => { setStatusFilter(e.target.value); }}>
                  {Object.values(StatusFilter).map(({ label, value }) => (
                    <Radio.Button key={value} value={value}>{label}</Radio.Button>  
                  ))}
                </Radio.Group>
              </div>
              <div>
                <Typography.Text strong className="is-block">Medición donde se creó:</Typography.Text>
                <Select
                  showSearch
                  style={{ width: 300 }}
                  value={projectFilter}
                  onChange={(value) => { setProjectFilter(value); }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  notFoundContent={(null)}
                  options={projectFilterOptions}
                />
              </div>
              <div>
                <Typography.Text strong className="is-block">Buscador:</Typography.Text>
                <Input.Search
                  style={{ width: 300 }}
                  placeholder="Plan de acción, Alcance, Creado por..."
                  onBlur={event => { setWordFilter(event.target.value); }}
                  onSearch={(value) => { setWordFilter(value); }}
                  allowClear
                />
              </div>
              {showTagFeature && (
                <Spin size="small" spinning={isLoadingTags}>
                  <Typography.Text strong className="is-block">Tag:</Typography.Text>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    value={tagFilter}
                    onChange={(value) => { setTagFilter(value); }}
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    notFoundContent={(null)}
                    options={tagFilterOptions}
                  />
                </Spin>
              )}
            </div>
            <Divider />
            <div className="columns">
              <div className="column">
                <StatusSummary summary={summary} />
              </div>
              <div className="column is-narrow">
                <ExportActionPlans />
              </div>
            </div>
            <div className="columns">
              <div className="column is-narrow">
                <label><h4>{`Planes de acción activos (${tableActiveData.length})`}</h4></label>
              </div>
              <div className="column has-text-right-desktop hide-on-print">
                <label style={{backgroundColor: getActionPlanPriorityData(2).color}}>&nbsp;&nbsp;</label>
                <label className="priority-text">{getActionPlanPriorityData(2).text}</label>
                <label style={{backgroundColor: getActionPlanPriorityData(1).color}}>&nbsp;&nbsp;</label>
                <label className="priority-text">{getActionPlanPriorityData(1).text}</label>
                <label style={{backgroundColor: getActionPlanPriorityData(0).color}}>&nbsp;&nbsp;</label>
                <label className="priority-text">{getActionPlanPriorityData(0).text}</label>
              </div>
            </div>
            <Table
              size="small"
              showSorterTooltip={false}
              dataSource={tableActiveData}
              columns={tableColumns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>
          <div className="box break-on-print">
            <label><h4>{`Planes de acción cerrados (${tableClosedData.length})`}</h4></label>
            <Table
              className="action-plan-closed"
              size="small"
              showSorterTooltip={false}
              dataSource={tableClosedData}
              columns={tableColumns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>    
        </div>
      ))}
    </div>
  );
};
