import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext.jsx";

import { getExportActionPlansURL } from "../../services/task-tracking";
import IconExportXLSX from '../../images/export-xlsx.ico';
import { Tooltip } from 'antd';

export default function ExportActionPlans() {
  const { session } = useContext(GlobalContext);

  return (
    <div className="hide-on-print">
      <Tooltip title="Exportar planes de acción">
        <a href={getExportActionPlansURL(session.accessToken, session.qsArray)}>
          <img
            className="m-2 is-clickable"
            src={IconExportXLSX}
            alt="Exportar planes de acción"
            height="30"
            width="30"
          />
        </a>
      </Tooltip>
    </div>
  );
};
