import React, { useContext } from "react";

import { Progress } from 'antd';
import { GlobalContext } from "../contexts/GlobalContext";

export default function ProgressBar(props) {

  const { getIndicatorColor } = useContext(GlobalContext);

  const percent = props.percent;
  const type = props.type || "line";
  const indicator = props.indicator || "default";  // ['default' | 'attribute']
  const size = props.size || "small";
  const color = getIndicatorColor(indicator, percent);
  const format = (value) => {
    if (typeof percent !== 'number') { return 'N/A'; }
    if (indicator === 'default') { return `${value}%`; }
    return`${percent}`;
  };

  return (
    <div className="progressbar-container">
      <Progress strokeColor={color} type={type} size={size} percent={percent} format={format} />
    </div>
  );
};
