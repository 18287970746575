import React from "react";

import logo from '../../images/logoAS_blanco_72dpi.png';

export default function Footer() {
  const copyright = `© 2004 - ${new Date().getFullYear()} Auditor Service S.C.`;
  return (
    <div className="footer-container">
      <footer>
        <div>
          <div className="columns is-vcentered">
            <div className="column is-paddingless has-text-centered-mobile">
              <img src={logo} alt="Auditor Service" />
            </div>
            <div className="column is-paddingless has-text-centered-mobile  has-text-right-tablet">
              <label>{copyright}</label>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};