import { asysgoConfig, ttgoConfig } from '../environment/config';


/**
* @param endpoint String
* @returns String with the complete URL for an endpoint set in environment file.
*/
export function getAsysgoURL(endpoint) {
    return `${asysgoConfig.baseUrl}${asysgoConfig.endpoints[endpoint]}`;
  }

/**
* @param endpoint String field set in environment.endpoints
* @returns String with the complete URL for an endpoint set in environment file.
*/
export function getTTgoURL (endpoint) {
    return `${ttgoConfig.baseUrl}${ttgoConfig.endpoints[endpoint]}`;
}

/**
* @param url Sting wich contains an URL with parameters format as :param. For example: /login/:username/:passwd
* @param params Array with objects with the param id and the param value. For example: [{id:'username', value: 'user01'}, {id:'passwd', value: '123'}]
* @param qsArray Array with objects with the qs key and the qs value. For example: [{id:'test', value: '123'}, {id:'anotherKey', value: 'example-value'}]
* @returns String with the params of the endpoint replaced. For example: /login/user01/123?test=123&anotherKey=example-value
*/
export function setParams(urlStr, params, qsArray) {
    if (Array.isArray(params)) {
        params.forEach(({ id, value }) => {
            const regExp = new RegExp(`:${id}`);
            if (regExp.test(urlStr)) {
                urlStr = urlStr.replace(`:${id}`, replaceSpecialChars(String(value)));
            }
        });
    }

    const url = new URL(urlStr);

    if (Array.isArray(qsArray)) {
        qsArray.forEach(({ id, value }) => {
            url.searchParams.append(id, value);
        });
    }

    return url.toString();
}

function replaceSpecialChars(str) {
    /*
     * Add more if it's required, source info:
     * https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
     */
    str = str.replace(/ /g, '%20');
    str = str.replace(/:/g, '%3A');
    str = str.replace(/\n/g, '%0D%0A');
    return str;
}
