import { ScopeKeySep, ScopeTypes } from "./constants.js";
/**
* @param tree Array of Objects (with a keys "key": <string> and "children":<same_object_type>)
* @returns Array of string - Return all keys found in obj
*/
export function getTreeKeys(tree) {
  const keys = [];
  if (Array.isArray(tree)) {
    tree.forEach(({ key, children }) => {
      keys.push(...[key].concat(getTreeKeys(children)));
    });
  }
  return keys;
}


/**
* @param scopeMetadata Object with metadata required to set session scope vars
* @returns Object - Return session scope vars to be set in session
*/
export function getSessionScopeVars(scopeMetadata) {
  const sessionVars = {
    qsScopeType: '',
    qsScopeKeys: '',
    qsArray: null,
  }

  if (scopeMetadata?.scopeFilters?.scopeType) {
    const qsArray = [{ id: 'scopeType', value: scopeMetadata.scopeFilters.scopeType }];
    if (scopeMetadata.scopeFilters.scopeFieldsKeys) {
      Object.entries(scopeMetadata.scopeFilters.scopeFieldsKeys).forEach(([k,v]) => {
        qsArray.push({ id: k, value: v });
      });
    }
    sessionVars.qsArray = qsArray;

    if (scopeMetadata?.scopeTypeLabel) {
      sessionVars.qsScopeType = scopeMetadata.scopeTypeLabel;
    }
    
    if (scopeMetadata?.scopeKeysLabel) {
      sessionVars.qsScopeKeys = scopeMetadata.scopeKeysLabel;
    }    
  }

  return sessionVars;
}

/**
* @param countryCode string - code related to report country filter
* @param stateCode string - code related to report state filter
* @param regionCode string - code related to report region filter
* @param zoneCode string - code related to report zone filter
* @param cityCode string - code related to report city filter
* @param shopID int - code related to report shop filter
* @param shopTypeCode string - code related to report shopType filter
* @param businessTypeCode string - code related to report businessType filter
* @returns Object - Return scope wanted by the user
*/
export function getPreselectedScope(countryCode, stateCode, regionCode, zoneCode, cityCode, shopID, shopTypeCode, businessTypeCode) {
  // Si tiene shopID (no importa que venga o sin locaciones) se considera este alcance y se deberá luego deducir la key completa
  if (shopID !== undefined) {
    return {
      type: ScopeTypes.Shop,
      key: ScopeKeySep + shopID,
      keyHasShop: true,
    }
  }

  // Si tiene al menos la locacion countryCode se considera este alcance
  if (countryCode !== undefined) {
    const obj = {
      type: ScopeTypes.Country,
      key: countryCode,
    };
    // Si ademas tiene la locacion stateCode se considera este alcance
    if (stateCode !== undefined) {
      obj.type = ScopeTypes.State;
      obj.key += ScopeKeySep + stateCode;
      // Si ademas tiene la locacion regionCode se considera este alcance
      if (regionCode !== undefined) {
        obj.type = ScopeTypes.Region;
        obj.key += ScopeKeySep + regionCode;
        // Si ademas tiene la locacion zoneCode se considera este alcance
        if (zoneCode !== undefined) {
          obj.type = ScopeTypes.Zone;
          obj.key += ScopeKeySep + zoneCode;
          // Si ademas tiene la locacion cityCode se considera este alcance
          if (cityCode !== undefined) {
            obj.type = ScopeTypes.City;
            obj.key += ScopeKeySep + cityCode;
            // Si ademas tiene la locacion shopID se considera este alcance
            if (shopID !== undefined) {
              obj.type = ScopeTypes.Shop;
              obj.key += ScopeKeySep + shopID;
            }
          }
        }
      }
    }
    return obj;
  }

  // Si tiene shopTypeCode se considera este alcance
  if (shopTypeCode !== undefined) {
    return {
      type: ScopeTypes.ShopType,
      key: shopTypeCode,
    };
  }

  // Si tiene businessTypeCode se considera este alcance
  if (businessTypeCode !== undefined) {
    return {
      type: ScopeTypes.BusinessType,
      key: businessTypeCode,
    };
  }

  // Por default si no se obtuvieron los codes esperador se considera alcance global
  return {
    type: ScopeTypes.Global,
    key: undefined,
  };
}
