const local = {
  ttgoConfig: {
    // baseUrl: "http://localhost:3003/api/", // localhost
  },
  asysgoConfig: {
    // baseUrl: 'http://localhost:3002/api/', // localhost
    // baseUrl: 'http://localhost:4444/api/', // ssh
  },
};

const prod = {};

const customConfig = process.env.NODE_ENV === 'production' ? prod : local;

export const ttgoConfig = {
  baseUrl: "https://task-tracking.auditorservice.com/api/",
  endpoints: {
    login: "v1/login",
    deprecatedActionPlans: "v1/deprecated/action-plans",
    actionPlans: "v1/action-plans",
    summaryActionPlans: "v1/summary/action-plans",
    exportActionPlans: "v1/export/action-plans/access-token/:accessToken",
    actionPlan: "v1/action-plans/:actionPlanID",
    comments: "v1/action-plans/:actionPlanID/comments",
    tasks: "v1/action-plans/:actionPlanID/tasks",
    task: "v1/action-plans/:actionPlanID/tasks/:taskID",
    uploadRequest: "v1/action-plans/:actionPlanID/tasks/:taskID/attachments/upload-request/:quantity",
    attachments: "v1/action-plans/:actionPlanID/tasks/:taskID/attachments",
    customerTags: "v1/tags",
  },
  ...customConfig.ttgoConfig,
};

export const asysgoConfig = {
  baseUrl: 'https://dashboards-go-cdn.auditorservice.com/api/',
  endpoints: {
    ttScopes: 'v1/tt-scopes', 
    ttScore: 'v1/tt-score',
  },
  ...customConfig.asysgoConfig,
};