import React from 'react';
import { node } from 'prop-types';
import { ConfigProvider, theme } from "antd";

export default function AntdConfigProvider({ children }) {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Breadcrumb: {
            iconFontSize: token.fontSizeHeading5,
            itemColor: token.colorPrimary,
            linkColor: token.colorLink,
            linkHoverColor: token.colorLinkHover,
            separatorColor: token.colorPrimary,
            lastItemColor: token.colorLinkActive,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

AntdConfigProvider.propTypes = {
  children: node.isRequired,
};
