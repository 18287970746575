import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import '../css/styles.css';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import advancedFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { AppRoutes } from "../utils/app-routes";

import AntdConfigProvider from "./contexts/AntdConfigProvider.jsx";
import GlobalContextProvider from "./contexts/GlobalContext.jsx";
import MainScreen from "./MainScreen.jsx";

import LoginScreen from "./LoginScreen.jsx";
import DeprecatedLoginScreen from "./DeprecatedLoginScreen.jsx";

import DeprecatedNewPlanScreen from "./DeprecatedNewPlanScreen.jsx";
import CreateActionPlanScreen from "./CreateActionPlanScreen.jsx";

import LogoutScreen from "./LogoutScreen.jsx";
import MaintenanceScreen from "./MaintenanceScreen.jsx";
import ForbiddenScreen from "./ForbiddenScreen.jsx";

import TaskScreen from "./TaskScreen.jsx";
import ActionPlanScreen from "./ActionPlanScreen.jsx";
import ActionPlanListScreen from "./ActionPlanListScreen.jsx";
import SummaryActionPlansScreen from "./SummaryActionPlansScreen.jsx";

import DefaultScreen from "./DefaultScreen.jsx";

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export default function App() {
  return (
    <AntdConfigProvider>
      <GlobalContextProvider>
        <Router>
          <MainScreen>
            <Routes>
              <Route path={AppRoutes.DeprecatedLogin} element={<DeprecatedLoginScreen />} />
              <Route path={AppRoutes.Login} element={<LoginScreen />} />
              <Route path={AppRoutes.DeprecatedNewActionPlan} element={<DeprecatedNewPlanScreen />} />
              <Route path={AppRoutes.CreateActionPlan} element={<CreateActionPlanScreen />} />
              <Route path={AppRoutes.Logout} element={<LogoutScreen />} />
              <Route path={AppRoutes.Maintenance} element={<MaintenanceScreen />} />
              <Route path={AppRoutes.Forbidden} element={<ForbiddenScreen />} />
              <Route path={AppRoutes.Task} element={<TaskScreen />} />
              <Route path={AppRoutes.ActionPlan} element={<ActionPlanScreen />} />
              <Route path={AppRoutes.ActionPlanList} element={<ActionPlanListScreen />} />
              <Route path={AppRoutes.HomePage} element={<SummaryActionPlansScreen />} />
              <Route path="*" element={<DefaultScreen />} />
            </Routes>
          </MainScreen>
        </Router>
      </GlobalContextProvider>
    </AntdConfigProvider>
  );
};
