import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext.jsx";
import { defaultCatchValidation, getTask, updateTask, deleteTask, uploadFiles } from "../services/task-tracking";
import dayjs from "dayjs";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Breadcrumb, Button, Input, Modal, Select, Spin, Upload, message} from "antd";
import { Comment } from "@ant-design/compatible";
import { CopyOutlined, DeleteOutlined, EditFilled, HomeFilled, InboxOutlined } from '@ant-design/icons';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import LoadSpinner from "./shared/LoadSpinner.jsx";
import { AppRoutes, ResolvedAppRoutes } from "../utils/app-routes.js";
import { classNamesInput, classNamesInputTextarea, classNamesModal } from "../utils/constants.js";

const { Dragger } = Upload;

let ac = new AbortController();

export default function TaskScreen() {
  const navigate = useNavigate();
  const { actionPlanID, taskID } = useParams();

  const { session, getTaskStatusData } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState({});

  const [actionPlan, setActionPlan] = useState({});
  const [textCopy, setTextCopy] = useState("");

  // Data used to edit AssignTo field
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [hasAssignableUsers, setHasAssignableUsers] = useState(false);

  // Show attachments section
  const [attachments, setAttachments] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);
  
  // Upload new attachments section
  const [uploadingFileList, setUploadingFileList] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreviewList, setFilePreviewList] = useState([]);
  const handleUploadFileList = () => {
    setUploadingFileList(true);
    uploadFiles(session.accessToken, actionPlanID, taskID, fileList)
    .then(json => {
      setAttachments(json.attachments);
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    })
    .finally(() => {
      setUploadingFileList(false);
      setFilePreviewList([]);
      setFileList([]);
    });
  };

  // Modal: Delete task
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [deletingTask, setDeletingTask] = useState(false);
  const showDeleteModal = () => {
    setIsVisibleDeleteModal(true);
  };
  const handleDeleteYes = () => {
    if (deletingTask) {return;}

    setDeletingTask(true);
    deleteTask(session.accessToken, actionPlanID, taskID)
    .then(json => {
      navigate(ResolvedAppRoutes.ActionPlan({ actionPlanID: actionPlanID }));
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    })
    .finally(() => {
      setIsVisibleDeleteModal(false);
      setDeletingTask(false);
    });
  };
  const handleDeleteNo = () => {
    if (deletingTask) {return;}
    setIsVisibleDeleteModal(false);
  };

  // Modal: Edit task
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editTitleRequired, setEditTitleRequired] = useState(false);
  const [editAssignedTo, setEditAssignedTo] = useState(0);
  const [editAssignedToRequired, setEditAssignedToRequired] = useState(false);
  const [editStatus, setEditStatus] = useState("false");
  const [editObservations, setEditObservations] = useState("");
  const [editTextDetails, setEditTextDetails] = useState("");
  
  const [updatingTask, setUpdatingTask] = useState(false);
  const showEditModal = () => {
    setEditTitle(task.title);
    setEditAssignedTo(task.assignedTo);
    setEditStatus(""+task.status);
    setEditObservations(task.observations);
    setEditTextDetails(task.textDetails);
    setIsVisibleEditModal(true);
  };
  const handleEditSave = () => {
    if (updatingTask) {return;}

    // Validations
    let isValid = true;
    const patchedTitle = String(editTitle).trim();
    if (!patchedTitle) {
      isValid = false;
      setEditTitleRequired(true);
    }

    if (!editAssignedTo) {
      isValid = false;
      setEditAssignedToRequired(true);
    }
    

    if(!isValid) {return;}

    const patchedObservations = editObservations.trim();
    setUpdatingTask(true);
    updateTask(session.accessToken, actionPlanID, task.id, patchedTitle, editTextDetails, Number(editAssignedTo), editStatus, patchedObservations)
    .then(json => {
      setTask(json.task);
    })
    .catch(json => {
      defaultCatchValidation(json, navigate);
    })
    .finally(() => {
      setIsVisibleEditModal(false);
      setUpdatingTask(false);
    });
  };
  const handleEditCancel = () => {
    if (updatingTask) {return;}
    setIsVisibleEditModal(false);
  };

  // on change actionPlan or task data: update text for CopyToClipboard 
  useEffect(() => {
    setTextCopy(
`Estimado/a ${task.assignedFullname}

  El usuario ${task.createdFullname} le ha asignado la siguiente tarea:

    - Nombre del cliente: ${session.companyName}
    - Nombre del proyecto: ${session.customerName}
    - Alcance del plan de acción: ${actionPlan.scopeName}
    - Nombre del plan de acción: ${actionPlan.title}
    - Descripción del plan de acción: ${actionPlan.description}
    - Nombre de la tarea: ${task.title}
    - Descripción de la tarea: ${task.textDetails}
    - Fecha de creación: ${dayjs(task.createdDate).format("DD/MM/YYYY")}
    - Fecha de vencimiento: ${dayjs(actionPlan.deadlineDate).format("DD/MM/YYYY")}

  Cualquier duda, favor de comunicarse con ${task.createdFullname} ${task.createdEmail}`
    );
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPlan, task]);

  useEffect(() => {
    if (
      isNaN(actionPlanID) || actionPlanID <= 0 ||
      isNaN(taskID) || taskID <= 0
    ) {
      navigate(AppRoutes.Forbidden);
      return;
    }

    if (session.accessToken === undefined) {
      navigate(AppRoutes.Logout);
      return;
    }

    const getData = () => {
      setLoading(true);

      ac.abort();
      ac = new AbortController();

      getTask(ac, session.accessToken, actionPlanID, taskID)
        .then(json => {
          setTask(json.task);
          setAttachments(json.attachments);
          setAssignableUsers(json.assignableUsers.sort((a, b) => ("" + a.name + a.username).localeCompare(""+ b.name + b.username)))
          setHasAssignableUsers(json.hasAssignableUsers);
          setActionPlan(json.actionPlan);
        })
        .catch(json => {
          defaultCatchValidation(json, navigate);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="task-screen-container">
      {(loading ? (
        <LoadSpinner />
      ):(
        <div className="weglot-translate">
          <Breadcrumb
            className="is-breadcrumb"
            separator=">"
            items={[
              { href: AppRoutes.HomePage, title: (<HomeFilled/>) },
              { href: AppRoutes.ActionPlanList, title: (<strong>Planes de acción</strong>) },
              { href: ResolvedAppRoutes.ActionPlan({ actionPlanID: actionPlanID }), title: (<strong>Plan de acción de la tarea</strong>) },
              { title: (<label>Tarea actual</label>) },
            ]}
          />

          <div className="box">
              <div className="columns">
                <div className="column is-narrow">
                <label><h4>{task.title}</h4></label>
                </div>
                <div className="column has-text-right-desktop">
                  <CopyToClipboard
                    text={textCopy}
                    onCopy={() => {}}
                  >
                    <Button className="fixed-margin-horizontal" icon={<CopyOutlined/>} onClick={()=>{message.success("Datos copiados al portapapeles.");}}>Copiar</Button>
                  </CopyToClipboard>
                  <Button className="fixed-margin-horizontal" icon={<DeleteOutlined/>} onClick={showDeleteModal}>Eliminar</Button>
                  <Button className="fixed-margin-horizontal" icon={<EditFilled/>} onClick={showEditModal}>Editar</Button>
                </div>
              </div>

              <div className="columns is-narrow">
                <div className="column is-4">
                  <div className="is-flex">
                    <label className="label-title">Fecha de creación</label>
                    <label className="label-data">{dayjs(task.createdDate).format("DD/MM/YYYY")}</label>
                  </div>
                  <div className="is-flex">
                    <label className="label-title">Creado por</label>
                    <label className="label-data">{task.createdFullname}</label>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="is-flex">
                    <label className="label-title">Asignado a</label>
                    <label className="label-data">{task.assignedFullname}</label>
                  </div>
                  <div className="is-flex">
                    <label className="label-title">Estado</label>
                    <label className="label-data" style={{color: getTaskStatusData(task.status).color}}>{getTaskStatusData(task.status).text}</label>
                  </div>
                </div>
                {task.observations && (
                <div className="column is-4">
                  <div className="is-flex">
                    <label className="label-title">Observaciones</label>
                  </div>
                  <div className="is-flex">
                    <label className="label-data">{task.observations}</label>
                  </div>
                </div>
                )}
              </div>

              {task.textDetails && (
              <Comment
                content={(<p>{task.textDetails}</p>)}
              />
              )}
          </div>

          <div className="box">
            <label><h5>{`Imagenes adjuntas (${attachments.length})`}</h5></label>
            <Spin tip="Cargando archivos" size="large" spinning={uploadingFileList}>
              <Dragger
                accept="image/*"
                multiple={true}
                listType="picture"
                onPreview={() => {}}
                onRemove={file => {
                    const index = fileList.findIndex(e => e.uid === file.uid);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                }}
                beforeUpload= {(file, files) => {
                  setFileList(fileList.concat(files));
                  return false; // Required to avoid auto upload
                }}
                fileList={filePreviewList}
                onChange={info => setFilePreviewList(info.fileList)}
              >
                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                <p className="ant-upload-text">Arrastra archivos o clickea aqui para adjuntar imagenes</p>
                <p className="ant-upload-hint">Puede cargar un o más archivos al mismo tiempo.</p>
              </Dragger>
              <Button
                type="primary"
                onClick={handleUploadFileList}
                disabled={fileList.length === 0}
                loading={uploadingFileList}
                style={{ margin: 16 }}
              >Subir archivos</Button>
            </Spin>

            <div className="columns is-multiline is-centered is-vcentered">
            {attachments.map((element, index) => (
              <div key={element.id} className="column is-3 is-narrow has-text-centered">
                <div className="image-container" onClick={() => { setPopupIndex(index); setPopupOpen(true);}}>
                  <div className="image-thumbnail" style={{backgroundImage: `url(${element.url})`}}>
                  </div>
                  <div className="image-info-container">
                    <label><strong>{element.filename}</strong></label><br/>
                    <label><i>{element.createdFullname}</i></label><br/>
                    <label><i>{dayjs(element.createdDate).format("DD/MM/YYYY HH:mm:ss")}</i></label>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>

        </div>
      ))}

      <div className="weglot-translate">
        <Modal
          title="Editar tarea"
          classNames={classNamesModal}
          open={isVisibleEditModal}
          closable={false}
          okText="Guardar"
          cancelText="Cancelar"
          onOk={handleEditSave}
          onCancel={handleEditCancel}
        >
          <Spin tip="Guardando cambios" size="large" spinning={updatingTask}>
            <div className="field">
              <div className="control">
                <label>Título:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="50"
                  placeholder="Ingrese título.."
                  value={editTitle}
                  onChange={event => {
                    setEditTitle(event.target.value);
                    setEditTitleRequired(false);
                  }}
                />
                  {editTitleRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Asignado a:</label><br/>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    size="large"
                    placeholder="Seleccione usuario"
                    optionFilterProp="children"
                    value={editAssignedTo}
                    onChange={value => {
                      setEditAssignedTo(value);
                      setEditAssignedToRequired(false);
                    }}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    notFoundContent={(null)}
                    options={assignableUsers.map(user => ({
                        value: user.id,
                        label: `${user.name} (${user.username})`,
                    }))}
                  />
                  {!hasAssignableUsers && (
                    <label className="has-text-danger">
                      * No se encontraron usuarios asignables para las tareas.
                    </label>
                  )}
                  {editAssignedToRequired && (
                    <label className="has-text-danger">
                      * Requerido
                    </label>
                  )}
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Estado:</label><br/>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    value={editStatus}
                    onChange={value => setEditStatus(value)}
                    options={[
                      { value: "false", label: getTaskStatusData(false).text },
                      { value: "true", label: getTaskStatusData(true).text },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Observaciones:</label><br/>
                <Input
                  size="large"
                  classNames={classNamesInput}
                  maxLength="150"
                  placeholder="(opcional) Observaciones de la tarea ..."
                  value={editObservations}
                  onChange={event => {
                    setEditObservations(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>Descripción detallada:</label><br/>
                <Input.TextArea
                  style={{ height: 220, resize: 'none' }}
                  classNames={classNamesInputTextarea}
                  size="large"
                  placeholder="(opcional) Descripción detallada de la tarea ..."
                  value={editTextDetails}
                  onChange={event => {
                    setEditTextDetails(event.target.value);
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>

      <div className="weglot-translate">
        <Modal
          title="Eliminar tarea"
          classNames={classNamesModal}
          open={isVisibleDeleteModal}
          closable={false}
          okText="Si"
          cancelText="No"
          onOk={handleDeleteYes}
          onCancel={handleDeleteNo}
        >
          <Spin tip="Guardando cambios" size="large" spinning={deletingTask}>
            <div>
              <label>¿ Está seguro que desea eliminar la tarea de forma permanente ?</label>
            </div>
          </Spin>
        </Modal>
      </div>

      {popupOpen && (
      <Lightbox
        mainSrc={attachments[popupIndex].url}
        nextSrc={attachments[(popupIndex + 1) % attachments.length]}
        prevSrc={attachments[(popupIndex + attachments.length - 1) % attachments.length]}
        imageLoadErrorMessage="Cargando imagen..."
        onCloseRequest={() => setPopupOpen(false)}
        onMovePrevRequest={() => setPopupIndex((popupIndex + attachments.length - 1) % attachments.length)}
        onMoveNextRequest={() => setPopupIndex((popupIndex + 1) % attachments.length)}
      />
      )}

    </div>
  );
};
