import React from "react";

export default function ForbiddenScreen() {

  return (
    <div className="loading-block weglot-translate">
      <label>{"No tiene permisos de acceso."}</label>
    </div>
  );
};
