import message from 'antd/lib/message';
import { getAsysgoURL } from '../utils/request-utils';
import { AppRoutes } from '../utils/app-routes';


/**
 * @param userToken Header auth - string
 * @param customerCode Body data - string
 * @param projectID Body data - number
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getTTScopes(
  userToken,
  customerCode,
  projectID,
) {
  const url = getAsysgoURL('ttScopes');

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      customerCode,
      projectID,
    }),
  };

  return sendRequest(url, options);
}

/**
 * @param ac AbortController object
 * @param userToken Header auth - string
 * @param customerCode Body data - string
 * @param projectID Body data - number
 * @param scopeType Body data - string
 * @param scopeFields Body data - object|undefined
 * @param originType Body data - string
 * @param originFields Body data - object|undefined
 * @param showTitle Body data - bool
 * @returns Response JSON data in resolve; Response JSON error in reject.
 */
function getTTScore(
  ac,
  userToken,
  customerCode,
  projectID,
  scopeType,
  scopeFields,
  originType,
  originFields,
  showTitle,
  showRelatedTags,
) {
  const url = getAsysgoURL('ttScore');

  const options = {
    signal: ac.signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      customerCode,
      projectID,
      scopeType,
      scopeFields,
      originType,
      originFields,
      showTitle,
      showRelatedTags,
    }),
  };

  return sendRequest(url, options);
}

/* Public helpers */
const isAbortSignal = (json) => !isNaN(json.errorCode) && json.errorCode === 0;

const defaultCatchValidation = (json, navigate) => {
  // Check JSON error type
  if (!isNaN(json.errorCode) && json.message) {
    // Expected JSON data: Positive error-codes are generated in BE, any else is from fetch-service
    // FIXME: Keep sync with expected error codes: asysgo/internal/services/utils.go:12
    switch (json.errorCode) {
      case 2: {
        // Customer Code not-found
        window.console.error('Invalid Customer Code: ', json);
        navigate(AppRoutes.Forbidden);
        break;
      }
      case 1: {
        // Session token not-found/expired
        window.console.error('Expired session token: ', json);
        message.error(json.message);
        navigate(AppRoutes.Logout);
        break;
      }
      case 0: {
        // Abort signal triggered nothing to do
        break;
      }
      case -1: {
        // Fetch has been failed, maybe server is down
        window.console.error('Fetch failed');
        message.error(json.message);
        navigate(AppRoutes.Maintenance);
        break;
      }
      case -2: {
        // Cant parse response as JSON, for some reason backend failed.
        window.console.error('Bad JSON response');
        message.error("El servidor no pudo procesar la petición.");
        break;
      }
      default: {
        // Any other errorCode ignored
        window.console.error('Ignored error code:', json);
      }
    }
  } else {
    // Unexpected json data from backend: Validation error / Internal error.
    window.console.error('Unexpected error: ', json);
    message.error("El servidor no pudo validar la petición.");
  }
};

/* Private methods */
const abortSignal = { errorCode: 0, message: 'Abort signal.' };
const failFetch = { errorCode: -1, message: 'No se pudo establecer comunicación con el servidor.' };
const badJSON = { errorCode: -2, message: 'Error al obtener respuesta del servidor.' };

const sendRequest = (url, options) => new Promise((resolve, reject) => {
  fetch(url, options)
    .then((response) => {
      if (response.ok) {
        // Expected fetch response status (ok: 200)
        response
          .json()
          .then((json) => {
            // Expected response body data: JSON
            resolve(json);
          })
          .catch(() => {
            // Unexpected body response data: non-JSON
            reject(badJSON);
          });
      } else {
        response
          .json()
          .then((json) => {
            // Expected response body error data: JSON
            reject(json);
          })
          .catch(() => {
            // Unexpected response body error data: non-JSON
            reject(badJSON);
          });
      }
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        // Abort fetch signal triggered
        window.console.log('Abort signal !');
        reject(abortSignal);
      }
      // Unexpected fetch error
      window.console.log('Unexpected fetch ERROR catch: ', error);
      reject(failFetch);
    });
});

export {
  // Utils
  defaultCatchValidation, isAbortSignal,
  // Services
  getTTScopes,
  getTTScore,
};
